import { getCookie, hasCookie, setCookie } from 'cookies-next/lib';
import { anonymousCookieName, authCookieName } from 'helpers/userAuth';
import { toBase64 } from 'next/dist/next-server/lib/to-base-64';

export const createAnonymousToken = (getToken = false) => {
  const tokenInfo = {
    date: new Date(),
    code: Math.random(),
  };
  const token = toBase64(JSON.stringify(tokenInfo));
  setCookie(anonymousCookieName, token, { maxAge: 60 * 60 * 24 });

  if (getToken) {
    return token;
  }
};

export const getCurrentToken = () => {
  if (hasCookie(authCookieName)) {
    return {
      AUTHORIZATION: `Bearer ${getCookie(authCookieName)}`,
    };
  }

  if (hasCookie(anonymousCookieName)) {
    return {
      ANONYMTOKEN: getCookie(anonymousCookieName),
    };
  }

  const token = createAnonymousToken(true);
  return {
    ANONYMTOKEN: token,
  };
};
